<template>
  <base-view id="plan-videos">
    <view-section>
      <h1 class="mb-12 text-5xl font-semibold">Plan Videos</h1>
      <div class="flex flex-row flex-wrap items-center justify-between">
        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/plan-videos/jn0h9tael7">
            <h3 class="mb-4 text-xl font-semibold">FSA Overview</h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class=" mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/jn0h9tael7?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/plan-videos/narmv3kr4s">
            <h3 class="mb-4 text-xl font-semibold">HSA Overview</h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/narmv3kr4s?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/plan-videos/97bsjir1cp">
            <h3 class="mb-4 text-xl font-semibold">
              LPF Overview
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/97bsjir1cp?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/plan-videos/k4nqf77rxp">
            <h3 class="mb-4 text-xl font-semibold">
              HRA Overview
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/k4nqf77rxp?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/plan-videos/5pfymqwdww">
            <h3 class="mb-4 text-xl font-semibold">
              Commuter Overview
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/5pfymqwdww?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/plan-videos/qm5id14mng">
            <h3 class="mb-4 text-xl font-semibold">
              DCA Overview
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/qm5id14mng?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/plan-videos/288778849">
            <h3 class="mb-4 text-xl font-semibold">
              HSA Devenir Guide
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://player.vimeo.com/video/288778849"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/plan-videos/780805966">
            <h3 class="mb-4 text-xl font-semibold">
              HSA Devenir Investment - How Do I Use this Investment Tool?
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid
            rounded"
              src="https://player.vimeo.com/video/780805966?h=0ec833cd06&badge=0&autopause=0&player_id=0&app_id=58479 [player.vimeo.com]"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/plan-videos/thvr22tw51">
            <h3 class="mb-4 text-xl font-semibold">
              WealthCare Saver HSA High Yield
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/thvr22tw51?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/plan-videos/zjfzh4rvnd">
            <h3 class="mb-4 text-xl font-semibold">
              FSA/HSA/HRA - Comparison of Accounts
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/zjfzh4rvnd?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/plan-videos/zn2gnc2gew">
            <h3 class="mb-4 text-xl font-semibold">
              FSA/HSA - What Items are Eligible?
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/zn2gnc2gew?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/plan-videos/rsybt90qxy">
            <h3 class="mb-4 text-xl font-semibold">
              Open Enrollment - Managing HealthCare Finances
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/rsybt90qxy?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/plan-videos/xy41yyou4y">
            <h3 class="mb-4 text-xl font-semibold">
              Open Enrollment - How Much Should I Be Saving?
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/xy41yyou4y?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/plan-videos/5h3nbakrz9">
            <h3 class="mb-4 text-xl font-semibold">
              Open Enrollment - Choosing A Health Insurance
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/5h3nbakrz9?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <!-- <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <h3 class="mb-4 text-xl font-semibold">
            Employer Portal - Navigating the Employer Portal
          </h3>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.com/embed/medias/sti305r9g6.jsonp&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div> -->

        <!-- <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <h3 class="mb-4 text-xl font-semibold">
            Employer Portal - Benefit AccesAccount Reports
          </h3>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.com/embed/medias/awm7jhtq5u.jsonp&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div> -->

        <!-- <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <h3 class="mb-4 text-xl font-semibold">
            Employer Portal - Uploading Contributions and Payroll Deposits
          </h3>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.com/embed/medias/94xhhjq6sw.jsonp&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div> -->
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

export default {
  components: {
    BaseView,
    ViewSection
  }
};
</script>
